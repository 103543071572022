<template>
  <StandardizedContainer
    :card-test-attribute="ident"
    :title="title"
    :view="type"
    :name="name"
    :chart-id="ident"
    :wrapper-id="`${ident}-wrapper`"
    :csv-payload="csvPayload"
    :loading="loading"
    :error="error"
    :has-custom-tooltip="hasCustomTooltip"
    :toggle-size="toggleSize"
    :order-of-headers="orderOfHeaders"
    :has-weight-property="hasWeightProperty"
    :is-weight="isWeight"
    :source-data="sourceData"
    :start-year="startYear"
    :end-year="endYear"
    :default-start-year="defaultStartYear"
    :default-end-year="defaultEndYear"
    :disclaimer="disclaimer"
    :download-version="downloadVersion"
    :download-template-id="`bar-line-download-template-${ident}`"
    @change-start-year="$emit('changeStartYear', $event)"
    @change-end-year="$emit('changeEndYear', $event)"
    @expanded="onExpandContainer"
  >
    <template #extraIcons>
      <div>
        <slot name="extraIcons"></slot>
      </div>
    </template>
    <div class="relative">
      <div v-if="!loading" :id="`benchmark-chart-${ident}`" :class="getChartHeight">
        <base-bar-chart
          :class="getChartHeight"
          :chart-id="ident"
          :ident="ident"
          :chart-data="reactiveChartData"
          :tooltip-postfix="tooltipPostfix"
          :y-scale-label="yScaleLabel"
          :show-legend="false"
          :show-totals="false"
          :is-stacked="chartData.length > 1"
          :has-custom-tooltip="hasCustomTooltip"
          toggles-center
          :has-source-text="false"
          :is-expanded-container="isExpandedContainer"
        />
      </div>
    </div>
    <template v-if="chartPayload && chartPayload.datasets?.length > 0" #containerToggles>
      <standardize-toggle
        :datasets="chartPayload.datasets"
        :chart-id="ident"
        :toggles-center="false"
        :index-hidden="indexHidden"
        :index-bottom-line="indexBottomLine"
        @update-dataset="updateDataset"
      ></standardize-toggle>
    </template>
    <template v-if="downloadVersion === 'v2'" #downloadTemplate>
      <DownloadLayoutTemplate
        :template-id="`bar-line-download-template-${ident}`"
        :title="formattedTitle"
        :toggles="chartPayload.datasets"
        :index-hidden="indexHidden"
        :footer-text="`Benchmark Mineral Intelligence ${sourceData?.year || ''} ${
          sourceData?.quarter || ''
        }`"
      >
        <base-bar-chart
          class="h-[700px]"
          :chart-id="ident"
          :ident="ident"
          :chart-data="reactiveChartData"
          :tooltip-postfix="tooltipPostfix"
          :y-scale-label="yScaleLabel"
          :show-legend="false"
          :show-totals="false"
          :is-stacked="chartData.length > 1"
          :has-custom-tooltip="hasCustomTooltip"
          toggles-center
          :has-source-text="false"
          :is-expanded-container="isExpandedContainer"
          :animation="false"
        />
      </DownloadLayoutTemplate>
    </template>
  </StandardizedContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRaw } from 'vue'
import tinycolor from 'tinycolor2'
import resolveConfig from 'tailwindcss/resolveConfig'
import { Units } from '~/constants/general'
import tailwindConfig from '~~/tailwind.config'
import makeIdentString from '~/utils/makeIdentString'
import capitalize from '~/utils/capitalize'
import xlsxFormatNumber from '~/utils/chartData/xlsxFormatNumber'
import { getObjectToNumberXLSX } from '~/utils/chartData/sheetjs.util'
import useExpandContainer from '~/composables/useExpandContainer'
import useChartUtils from '~/composables/useChartUtils'
import useFilterDatasets from '~/composables/useFilterDatasets'

const colors = resolveConfig(tailwindConfig).theme.colors
const route = useRoute()

const props = withDefaults(
  defineProps<{
    palette?: string
    withTabs?: boolean
    toggleSize?: string
    indexBottomLine?: number
    firstToggleLabel?: string
    itemsWithoutLink?: any[]
    title?: string
    name?: string
    type?: string
    chartData?: any[]
    hasCustomTooltip?: boolean
    yScaleLabel?: string
    tooltipPostfix?: string
    hasWeightProperty?: boolean
    isWeight?: boolean
    propYear?: string
    withTooltipUrl?: boolean
    sortBy?: string
    sourceData?: {
      quarter: number
      year: string
    }
    startYear?: number
    endYear?: number
    defaultStartYear?: number
    defaultEndYear?: number
    disclaimer?: string
    downloadVersion?: 'v1' | 'v2'
  }>(),
  {
    palette: '',
    withTabs: true,
    toggleSize: 'medium',
    indexBottomLine: undefined,
    firstToggleLabel: 'Demand',
    itemsWithoutLink: () => [],
    title: '',
    name: '',
    type: '',
    chartData: () => [],
    hasCustomTooltip: false,
    yScaleLabel: `Supply (${Units.TPA})`,
    tooltipPostfix: Units.TPA,
    hasWeightProperty: false,
    isWeight: false,
    propYear: 'name',
    withTooltipUrl: false,
    sortBy: '',
    sourceData: null,
  },
)
const chartPayload = ref({
  datasets: [],
})
const csvPayload = ref([])
const orderOfHeaders = ref([])
const loading = ref(true)
const error = ref(null)

defineEmits(['changeStartYear', 'changeEndYear'])

const { indexHidden, updateDataset, reactiveChartData } = useFilterDatasets(chartPayload)
const { isExpandedContainer, onExpandContainer } = useExpandContainer()
const { sortDataByProduction, makeUrlDir } = useChartUtils()

const ident = computed(() => {
  return makeIdentString(props.title)
})

const formattedTitle = computed(() => {
  const product = route.path.split('/')[1]
  return getTitleByProduct({ name: props.name, view: props.type, chartId: ident.value, product })
})

const getChartHeight = computed(() => {
  if (!isExpandedContainer.value) return 'h-[385px]'
  if (props.withTabs) return 'h-full-screen-with-tabs'

  return 'h-full-screen'
})

const getTooltipUrl = (id, isItemWithoutLink, urlDir) => {
  return props.withTooltipUrl && !isItemWithoutLink ? `${urlDir}/${id}/` : null
}
const getKey = () => {
  return props.propYear === 'name' ? 'data' : 'value'
}
const getSortedData = () => {
  const clonnedChartData = structuredClone(toRaw(props.chartData))
  if (props.sortBy === 'production') {
    return sortDataByProduction(clonnedChartData)
  }
  return clonnedChartData
}
const makeCsvPayloadData = (keyData) => {
  // CSV DATA START
  csvPayload.value = []
  const headers = []
  const formatNumber = xlsxFormatNumber(props.tooltipPostfix)
  props.chartData.forEach((item, index) => {
    if (index === 0) {
      headers.push(capitalize(props.type))
    }
    const yearsAsRow = {}
    item.data.forEach((year) => {
      if (index === 0) {
        headers.push(year[props.propYear].toString())
      }
      yearsAsRow[year[props.propYear].toString()] = getObjectToNumberXLSX(
        year[keyData],
        formatNumber,
      )
    })
    const csvItem = {
      [capitalize(props.type)]: item.name,
      ...yearsAsRow,
    }
    csvPayload.value.push(csvItem)
  })
  orderOfHeaders.value = headers
  // CSV DATA END
}

onMounted(() => {
  const keyData = getKey()
  if (props.chartData.length) {
    const urlDir = makeUrlDir(props.type)
    const clonnedChartData = getSortedData()

    chartPayload.value = {
      labels: props.chartData[0].data.map((year) => year[[props.propYear]]),
      datasets: clonnedChartData.map((item, index) => {
        const type = index === 0 ? 'line' : 'bar'
        const barColor = props.palette ? colors[props.palette][item.name] : colors.chart.yellow
        const color = type === 'line' ? colors.chart.red : barColor
        const backgroundColor = tinycolor(color).setAlpha(index).toRgbString()

        const isItemWithoutLink = props.itemsWithoutLink.some(
          (itemWithoutLink) => itemWithoutLink.toLowerCase() === item.name.toLowerCase(),
        )
        const tooltipUrl = getTooltipUrl(item.id, isItemWithoutLink, urlDir)

        return {
          type,
          label: item.name,
          backgroundColor,
          borderColor: tinycolor(color).setAlpha(1.0).toRgbString(),
          data: item.data.map((year) => year[keyData]),
          url: tooltipUrl,
        }
      }),
    }

    if (props.sortBy) {
      chartPayload.value.datasets.reverse()
    }

    /* 'Demand' toggle most be 1st */
    const demandEl = chartPayload.value.datasets.find(
      (item) => item.label === props.firstToggleLabel,
    )
    chartPayload.value.datasets = chartPayload.value.datasets.filter(
      (item) => item.label !== props.firstToggleLabel,
    )
    chartPayload.value.datasets.push(demandEl)

    reactiveChartData.value = JSON.parse(JSON.stringify(chartPayload.value))
  }

  makeCsvPayloadData(keyData)

  loading.value = false
})
</script>
